.formsimple-input-bc {
	background-color: white;
	border: solid 1px $gray4;
	border-radius: 2rem;
	color: $gray1;
	display: inline-block;
	padding: .5rem 1rem !important;
	line-height: normal;
}

.width_input_home{
	width: 100% !important;
}

@media all and (max-width: 1023px){

	.width_input_home{
		width: 100% !important;
	}
}