/* Always set the map height explicitly to define the size of the div
* element that contains the map. */
.container_buscarmapa{
    display: block;
    width: 100%;
    height: 60vh;
    position: relative;

    & .img{
        display: block;
        width: 100%;
        height: 60vh;
        max-height: 60vh;
        background-image: url('/jnh/background/mapaimg.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
        & .texto_mapa{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
            max-width: 30vw;
            background-color: rgba(white, .8);
            padding: 1rem 2rem;
            border-radius: 1rem;
            box-shadow: 0 0 10px 5px rgba(black, .1);
            & h3{
                font-size: 1.6rem;
                color: $gray1;
                font-weight: 500;
                text-align: center;
            }
            & p{
                font-size: 1.2rem;
                color: $gray1;
                font-weight: 400;
                line-height: 2rem;
                text-align: center;
                margin-bottom: 1rem;
            }

            & button{
                display: block;
                margin: auto;
                border: none;
                cursor: pointer;
                background-color: transparent;
                & p{
                    background-color: $colorPrimary;
                    border-radius: 2rem;
                    padding: .5rem 1rem;
                    color: white;
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
        }

        & .texto_instrucao{
            position: absolute;
            left: 90%;
            top: 1%;
            transform: translate(-90%, 1%);
            width: 100%;
            max-width: 40vw;
            box-shadow: 0 0 10px 5px rgba(black, .1);
            & p{
                font-size: 1.2rem;
                color: $gray1;
                font-weight: 400;
                text-align: center;
                width: 100%;
                display: block;
                padding: .2rem .3rem;
                background-color: rgba(white, .8);
                border-radius: .5rem;
                margin: 0;

                & span{
                    color: $colorPrimary;
                    font-weight: 600;
                }
            }
        }
    }
    
    & #map {
        height: 60vh;
    }
}

#btns_buscamapa{
    position: absolute;
    left: 50%;
    top: -2rem;
    transform: translate(-50%, -2rem);
    & .btn1{
        padding: .5rem 1rem;
        border: solid 1px $colorPrimary;
        background-color: white;
        border-radius: 2rem;
        color: $colorPrimary;
        font-size: 1.1rem;
        font-weight: 600;
        box-shadow: -5px 5px 15px rgba(black, .2);
        cursor: pointer;
        &:hover{
            background-color: lighten($colorSecondary, 10);
        }
    }
    & .btn2{
        padding: .5rem 1rem;
        border: solid 1px $colorPrimary;
        background-color: $colorPrimary;
        border-radius: 2rem;
        color: white;
        font-size: 1.1rem;
        font-weight: 600;
        box-shadow: 5px 5px 15px rgba(black, .2);
        cursor: pointer;
        &:hover{
            background-color: darken($colorPrimary, 5);
        }
    }
}

@media all and (min-width: 1201px) and (max-width: 1366px){
    .container_buscarmapa{
        display: block;
        width: 100%;
        height: 70vh;
        position: relative;
    
        & .img{
            display: block;
            width: 100%;
            height: 70vh;
            max-height: 70vh;
            background-image: url('/jnh/background/mapaimg.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            
            & .texto_mapa{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 100%;
                max-width: 40vw;
                background-color: rgba(white, .9);
                padding: 1rem 2rem;
                border-radius: 1rem;
                box-shadow: 0 0 10px 5px rgba(black, .1);
                & h3{
                    font-size: 1.5rem;
                    color: $gray1;
                    font-weight: 500;
                    text-align: center;
                }
                & p{
                    font-size: 1.1rem;
                    color: $gray1;
                    font-weight: 400;
                    line-height: 2rem;
                    text-align: center;
                    margin-bottom: 1rem;
                }
    
                & button{
                    display: block;
                    margin: auto;
                    border: none;
                    cursor: pointer;
                    background-color: transparent;
                    & p{
                        background-color: $colorPrimary;
                        border-radius: 2rem;
                        padding: .5rem 1rem;
                        color: white;
                        font-size: 1.2rem;
                        font-weight: 500;
                    }
                }
            }
    
            & .texto_instrucao{
                position: absolute;
                left: 89%;
                top: 0%;
                transform: translate(-89%, 0%);
                width: 100%;
                max-width: 40vw;
                box-shadow: 0 0 10px 5px rgba(black, .1);
                & p{
                    font-size: 1rem;
                    color: $gray1;
                    font-weight: 400;
                    text-align: center;
                    width: 100%;
                    display: block;
                    padding: .2rem .3rem;
                    background-color: rgba(white, .8);
                    border-radius: .5rem;
                    margin: 0;
                    & span{
                        color: $colorPrimary;
                        font-weight: 600;
                    }
                }
            }
        }
        
        & #map {
            height: 70vh;
        }
    }
    
    #btns_buscamapa{
        position: absolute;
        left: 50%;
        top: -2rem;
        transform: translate(-50%, -2rem);
        & .btn1{
            padding: .5rem 1rem;
            border: solid 1px $colorPrimary;
            background-color: white;
            border-radius: 2rem;
            color: $colorPrimary;
            font-size: 1.1rem;
            font-weight: 600;
            box-shadow: -5px 5px 15px rgba(black, .2);
            cursor: pointer;
            &:hover{
                background-color: lighten($colorSecondary, 10);
            }
        }
        & .btn2{
            padding: .5rem 1rem;
            border: solid 1px $colorPrimary;
            background-color: $colorPrimary;
            border-radius: 2rem;
            color: white;
            font-size: 1.1rem;
            font-weight: 600;
            box-shadow: 5px 5px 15px rgba(black, .2);
            cursor: pointer;
            &:hover{
                background-color: darken($colorPrimary, 5);
            }
        }
    }
}

@media all and (min-width: 1024px) and (max-width: 1200px){
    .container_buscarmapa{
        display: block;
        width: 100%;
        height: 70vh;
        position: relative;
    
        & .img{
            display: block;
            width: 100%;
            height: 70vh;
            max-height: 70vh;
            background-image: url('/jnh/background/mapaimg.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            
            & .texto_mapa{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 100%;
                max-width: 45vw;
                background-color: rgba(white, .8);
                padding: 1rem 2rem;
                border-radius: 1rem;
                box-shadow: 0 0 20px 5px rgba(black, .3);
                & h3{
                    font-size: 1.3rem;
                    color: $gray1;
                    font-weight: 600;
                    text-align: center;
                }

                & p{
                    font-size: 1rem;
                    color: $gray1;
                    font-weight: 400;
                    line-height: 2rem;
                    text-align: center;
                    margin-bottom: 1rem;
                }
    
                & button{
                    display: block;
                    margin: auto;
                    border: none;
                    cursor: pointer;
                    background-color: transparent;
                    & p{
                        background-color: $colorPrimary;
                        border-radius: 2rem;
                        padding: .5rem 1rem;
                        color: white;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
            }

            & .texto_instrucao{
                position: absolute;
                left: 90%;
                top: 0%;
                transform: translate(-90%, 0%);
                width: 100%;
                max-width: 40vw;
                box-shadow: 0 0 20px 5px rgba(black, .3);
                & p{
                    font-size: 1rem;
                    color: $gray1;
                    font-weight: 400;
                    text-align: center;
                    width: 100%;
                    display: block;
                    padding: .2rem .3rem;
                    background-color: rgba(white, .8);
                    border-radius: .5rem;
                    margin: 0;
                    & span{
                        color: $colorPrimary;
                        font-weight: 600;
                    }
                }
            }
        }
        
        & #map {
            height: 70vh;
        }
    }
}

@media all and (max-width: 1023px){
    .container_buscarmapa{
        display: block;
        width: 100%;
        height: 70vh;
        position: relative;
    
        & .img{
            display: block;
            width: 100%;
            height: 70vh;
            max-height: 70vh;
            background-image: url('/jnh/background/mapaimg.jpg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            
            & .texto_mapa{
                position: absolute;
                left: 50%;
                top: 60%;
                transform: translate(-50%, -60%);
                display: block;
                width: 100%;
                max-width: 90vw;
                background-color: rgba(white, .8);
                padding: 1rem 2rem;
                border-radius: 1rem;
                box-shadow: 0 0 20px 5px rgba(black, .3);
                & h3{
                    font-size: 1.3rem;
                    color: $gray1;
                    font-weight: 600;
                    text-align: center;
                }

                & p{
                    font-size: 1rem;
                    color: $gray1;
                    font-weight: 400;
                    line-height: 2rem;
                    text-align: center;
                    margin-bottom: 1rem;
                }
    
                & button{
                    display: block;
                    margin: auto;
                    border: none;
                    cursor: pointer;
                    background-color: transparent;
                    & p{
                        background-color: $colorPrimary;
                        border-radius: 2rem;
                        padding: .5rem 1rem;
                        color: white;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
            }

            & .texto_instrucao{
                position: absolute;
                left: 50%;
                top: 10%;
                transform: translate(-50%, 10%);
                width: 100%;
                max-width: 70vw;
                box-shadow: 0 0 20px 5px rgba(black, .3);
                & p{
                    font-size: .9rem;
                    color: $gray1;
                    font-weight: 400;
                    text-align: center;
                    width: 100%;
                    display: block;
                    padding: .2rem .3rem;
                    background-color: rgba(white, .8);
                    border-radius: .5rem;
                    margin: 0;
                    & span{
                        color: $colorPrimary;
                        font-weight: 600;
                    }
                }
            }
        }
        
        & #map {
            height: 70vh;
        }
    }


    #btns_buscamapa{
        position: absolute;
        left: 50%;
        top: -3rem;
        transform: translate(-50%, -3rem);
        & .btn1{
            width: 100%;
            padding: .5rem 1rem;
            border: solid 1px $colorPrimary;
            background-color: white;
            border-radius: .1rem;
            color: $colorPrimary;
            font-size: 1.1rem;
            font-weight: 600;
            box-shadow: -5px 5px 15px rgba(black, .2);
            cursor: pointer;
            &:hover{
                background-color: lighten($colorSecondary, 10);
            }
        }
        & .btn2{
            width: 100%;
            padding: .5rem 1rem;
            border: solid 1px $colorPrimary;
            background-color: $colorPrimary;
            border-radius: .1rem;
            color: white;
            font-size: 1.1rem;
            font-weight: 600;
            box-shadow: 5px 5px 15px rgba(black, .2);
            cursor: pointer;
            &:hover{
                background-color: darken($colorPrimary, 5);
            }
        }
    }
}
