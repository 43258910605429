.galeriaa_left{

}

.imo_title{
	display: block;
	width: 100%;

	& h1{
		font-size: 1.5rem;
		font-weight: 500;
		color: $gray1;
	}

	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;
	}
}

.galeriaa_right{
	padding: .5rem 1rem;
	width: 100%;
	border-left: solid 2px $gray4;

	& .imo_codigo{
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;

		& span{
			font-size: 1rem;
			color: $colorPrimary;
			font-weight: 500;
		}
	}

	& .acao_tipo{
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;
	}

	& .aceita_financiamento {
		background-color: #454545;
		width: fit-content;
		padding: 6px 30px;
		border-radius: 2rem;
		color: #fff;
		font-weight: 500;
		text-transform: uppercase;
	}

	& .tarja_valor{
		font-size: 2rem;
		color: $colorPrimary;
		font-weight: 600;
	}

	& .valor_cond{
		& p{
			font-size: .9rem;
			color: $gray2;
			font-weight: 400;
			& i{
				vertical-align: middle;
				font-size: 1rem;
				color: $colorPrimary;
				font-weight: 600;
				cursor: pointer;
			}
		}
	}

	& .box_promocao_interna{
		display: block;
		width: 100%;
		& .title{
			font-size: 1rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}

	& .box_aceitatroca{
		display: block;
		width: 100%;
		& .title{
			font-size: 1rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}
}

.text_descricao_imo{
	& h2{
		font-size: 1.1rem;
		color: $gray1;
		font-weight: 500;
	}

	& p{
		font-size: 1rem;
		color: $gray2;
		font-weight: 400;
	}
}

.formulario_interno{
	background-color: white;
	padding: .5rem 1.5rem;
	border-radius: 2rem;
	border: solid 1px $gray4;

	& h4{
		font-size: 1.5rem;
		font-weight: 600;
		color: $colorPrimary;
	}

	& .btn_zap_interno{
		width: fit-content;
		display: block;
		background-color: #25d366;
		margin: auto;
		border-radius: 2rem;
		padding: 0 2rem;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		-webkit-animation: pulse 1.5s infinite;
		& p{
			text-align: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 600;
			padding: .5rem .2rem;
			vertical-align: middle;
			
			& i{
				vertical-align: middle;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}
		& .pulse-button:hover {
			-webkit-animation: none;
		}


		@-webkit-keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 20px rgba(#25d366, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#25d366, 0);
			}
		}
	}

	& .corretor_img{
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		& img{
			width: 100%;
			border-radius: 50%;
		}
	}

	& .corretor_dados{
		display: inline-block;
		vertical-align: middle;
		width: 80%;
		padding: 0 .5rem 0 0;
		& p{
			margin: .2rem 0;
			font-size: .9rem;
			font-weight: 500;
			color: $gray2;
		}
	}
	
}
.texto_descricao_imo{
	max-width: 55rem;
	margin-left: 0;
	line-height: 1.3rem;
	font-size: 1rem;
}

.width_pc_mobile{
	width: 55% !important;
}

.fav_button{
	text-align: center;
	margin: 0;
	width: 40%;
	display: inline-block;
	vertical-align: middle;
	background-color: white;
	border-radius: .2rem 0 0 .2rem;
	border: solid 1px $gray4;
	padding: .2rem 0;
	& p{
		margin: 0;
		text-align: left;
		vertical-align: middle;
		color: $gray3 !important;
		font-size: 1rem;
		font-weight: 500;
	}
	
}

.share_session{
	background-color: white;
	border-radius: 0 2rem 2rem 0;
	border-top: solid 1px $gray4;
	border-right: solid 1px $gray4;
	border-bottom: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	width: 60%;
	padding: 0.15rem 0;
	& .item2{
		& p{
			padding: 0;
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: 1rem;
			font-weight: 600;
		}
	}
	& .item{
		display: inline-block;
		text-align: center;
		width: 20%;
		//padding: 0 .5rem;
	}
}

.imprimir_session{
	background-color: white;
	border: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	border-radius: 2rem 0 0 2rem;
	width: 40%;
	padding: .15rem 0;
	& .item{
		display: inline-block;
		text-align: center;
		width: 55%;
		& p{
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: 1rem;
			font-weight: 600;
		}
	}
	& .item2{
		display: inline-block;
		text-align: center;
		width: 45%;
	}
}

/* 1200px */
@media all and (min-width: 1024px) and (max-width: 1380px){
	.fav_button{
		text-align: center;
		margin: 0;
		width: 40%;
		display: inline-block;
		vertical-align: middle;
		background-color: white;
		border-radius: .2rem 0 0 .2rem;
		border: solid 1px $gray4;
		padding: .18rem 0;
		& p{
			margin: 0;
			text-align: left;
			vertical-align: middle;
			color: $gray3 !important;
			font-size: 1rem;
			font-weight: 500;
		}
		
	}
}

/* MOBILE */
@media all and (max-width: 1023px){

	.imo_title{
		& h1{
			font-size: 1.3rem;
		}
	}

	.fav_button{
		text-align: center;
		margin: auto;
		width: 100%;
		background-color: white;
		border-radius: .1rem;
		border: solid 1px $gray4;
		padding: 0;
		& p{
			text-align: right;
			vertical-align: middle;
			padding: 0 1rem;
			color: $gray3 !important;
			font-size: 1rem;
			font-weight: 500;
		}
	}

	.galeriaa_right{
		padding: .5rem 1rem;
		width: 100%;
		border-left: solid 2px $gray4;
		& .tarja_valor{
			font-size: 2rem;
			color: $colorPrimary;
			font-weight: 600;
		}
	}

	.formulario_interno{
		background-color: white;
		padding: .5rem 1.5rem;
		border-radius: 1rem;
		border: solid 1px $gray4;
	
		& h4{
			font-size: 1.5rem;
			font-weight: 600;
			color: $colorPrimary;
		}
		
	}
	.texto_descricao_imo{
		width: 100%;
		line-height: 1.3rem;
		font-size: 1rem;
	}

	.width_pc_mobile{
		width: 100% !important;
	}

	.share_session{
		background-color: white;
		border-radius: 0 2rem 2rem 0;
		border: solid 1px $gray4;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		& .item2{
			& p{
				padding: 0;
				margin-left: 0.7rem;
				margin-right: 0.7rem;
				display: inline-block;
				color: $gray3;
				font-size: 1rem;
				font-weight: 600;
			}
		}
		& .item{
			display: inline-block;
			text-align: center;
			width: 20%;
		}
	}
	
	.avaliar_session{
		width: 100%;
		vertical-align: middle;
		display: inline-block;
		border-radius: 0 .2rem .2rem 0;
		border: solid 1px $gray4;
		& .item{
			background-color: white;
			width: 100%;
			border-radius: 0 .2rem .2rem 0 ;
			
			& p{
				margin: 0;
				color: $gray3;
				text-align: center;
				font-size: 1.2rem;
			}
		}
		& .b_avaliar_imo{
			background-color: white;
			border: solid 2px transparent;
			color: $colorPrimary;
			padding: 0 .5rem;
			cursor: pointer;
		}
	}

}