.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: 2px;
		right: 15px;
		pointer-events:none;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		content: "\f078";
		font-size: 14px !important;
		padding: 0;
		color: $gray2;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: white;
		color: #454545 !important;
		font-size: 0.9rem !important;
		font-weight: 400 !important;
		border: solid 1px #C3C3C3;
		border-radius: 2rem;
		padding: 0.5rem 1rem;
		text-align: left;
		line-height: normal;
	}
	& .component {
		border-top: none;
		border: 1px solid $gray4 !important;
		z-index: 10;
		border-radius: .5rem;
		& .items{
			overflow: auto;
			& .group{
				color: $gray2 !important;
			}
			
			& .group_itens{
			}
		}


		& .botoes {
			& .undo{
				background-color: white;
				color: $colorPrimary !important;
				font-weight: 600;
				cursor: pointer;
				font-size: .8rem;
				padding: .5rem 0;
				&:hover{
					background-color: darken(white, 5);
				}

			}

			& .ok{
				background-color: $colorPrimary;
				color: white !important;
				font-weight: 600;
				cursor: pointer;
				font-size: .8rem;
				padding: .5rem 0;
				&:hover{
					background-color: darken($colorPrimary,5);
				}
			}

			& button {
				display: block;
				margin: 0;
				outline: none;
				border: 0;
				background-color: transparent;
				height: inherit;
			}
		}
	}
}