.soraka_pai{
    display: block;
    width: 100%;
    position: relative;
    overflow: auto;

    & #soraka_left{
        position: absolute;
        top: 50%;
        left: 1%;
        background-color: rgba(black, 0);
        color: white;
        border: solid 1px transparent;
        padding: .5rem 1.5rem;
        border-radius: .1rem;
        font-weight: 600;
        font-size: 1.2rem;
        z-index: -50;
        cursor: pointer;
        &:hover{
            background-color: rgba(black, 0);
        }
    }

    & #soraka_right{
        position: absolute;
        top: 50%;
        right: 1%;
        background-color: rgba(black, 0);
        color: white;
        border: solid 1px transparent;
        padding: .5rem 1.5rem;
        border-radius: .1rem;
        font-weight: 600;
        font-size: 1.2rem;
        z-index: -50;
        cursor: pointer;
        &:hover{
            background-color: rgba(black, 0);
        }
    }

    & #soraka_play{
        position: absolute;
        bottom: 2%;
        right: 49%;
        background-color: rgba(black, 0);
        color: white;
        border: solid 1px transparent;
        padding: .1rem 1rem;
        border-radius: .1rem;
        font-weight: 600;
        font-size: .9rem;
        z-index: -50;
        cursor: pointer;
        &:hover{
            background-color: rgba(black, 0);
        }
    }
}

.soraka_pai::-webkit-scrollbar {
    display: none;
}

.soraka{
    display: block;
    width: 100%;
    position: absolute;
    background-color: rgb(131, 131, 131);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    will-change: position, animation;

    cursor: pointer;
    top: 0;
    right: 0;
}

.soraka div {
    position: absolute;
    top: 0;
    display: inline-block !important;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.soraka_um{
    will-change: position, animation;
    cursor: pointer;
    animation-duration: 5s;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-name: soraka_um;
}

@keyframes soraka_um {
    from {
        right: -100vw;
    }
    to {
        right: 0;
    }
}

@media all and (max-width: 1023px){
    /*.soraka div {
        background-size:auto;
        background-repeat: no-repeat;
    }*/
    .soraka_pai{
        display: block;
        width: 100%;
        position: relative;
        overflow: auto;
        & #soraka_left{
            position: absolute;
            top: 50%;
            left: 1%;
            background-color: rgba(black, 0);
            color: white;
            border: solid 1px transparent;
            padding: .2rem 1rem;
            border-radius: .1rem;
            font-weight: 600;
            font-size: 1.2rem;
            z-index: -50;
            cursor: pointer;
            &:hover{
                background-color: rgba(black, 0);
            }
        }
        & #soraka_right{
            position: absolute;
            top: 50%;
            right: 1%;
            background-color: rgba(black, 0);
            color: white;
            border: solid 1px transparent;
            padding: .2rem 1rem;
            border-radius: .1rem;
            font-weight: 600;
            font-size: 1.2rem;
            z-index: -50;
            cursor: pointer;
            &:hover{
                background-color: rgba(black, 0);
            }
        }
        & #soraka_play{
            position: absolute;
            bottom: 2%;
            right: 45%;
            background-color: rgba(black, 0);
            color: white;
            border: solid 1px transparent;
            padding: .1rem 1rem;
            border-radius: .1rem;
            font-weight: 600;
            font-size: .9rem;
            z-index: -50;
            cursor: pointer;
            &:hover{
                background-color: rgba(black, 0);
            }
        }
    }

    .soraka div {
        position: absolute;
        top: 0;
        display: inline-block !important;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: white;
    }
}
