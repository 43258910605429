.header1{
	background-color: $colorPrimary;
	width: 100%;
	z-index: 99999999;
}

.box_header{
	box-shadow: 0 0 20px 1px rgba(black, .05);
	z-index: 999;
	position: relative;
}

.header2{
	background-color: $colorPrimary;
	width: 100%;
	z-index: 99999999;
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: white !important;
	margin: 0%;
}

.header3{
	width: 100%;
	animation: header3pc .45s ease-in-out;
	animation-fill-mode: forwards;
	transform-origin: center center;
	z-index: 99999999;
}

@keyframes header3pc{
	0% {
		//background-image: linear-gradient(to bottom right, #00174900, #03428200);
		background-color: transparent;
	}
	100% {
		//background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
		background-color: $colorPrimary;
	}
}

.headerinterno {
	height: 5rem;
	z-index: 1;
}



#sub_menu_vendas{
	display: none;
	width: 200px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 50%;
	top: 2.7rem;
	background-color: white;
	box-shadow: 7px 7px 10px rgba(black, .1);
	border-radius: .5rem;
	border: solid 1px $gray2;
	z-index: 3;
	transform: translate(-50%);
	transition: all .2s;
	& .elementos_sub_menu{
		text-align: left;
		& p{
			font-size: 1rem !important;
			font-weight: 400;
			background-color: transparent;
			padding: .5rem 1.5rem;
			margin: 0;
			text-align: left;
			transition: all .15s;
			&:hover{
				padding-left: 2rem;
				background-color: darken($gray5, 5);
			}
		}
		& .titletipo{
			color: $colorPrimary;
			font-weight: 500;
		}

		& .itemtipo{
			color: $colorPrimary;
			&:hover{
				font-weight: 500;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;

	& .superior {
		display: block;
		width: 100%;
		z-index: 3;

		/* GRUPO HEADER */
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 20%;
			padding: 0;
			& .img{
				width: 100%;
				display: block;
				
				& img {
					display: block;
					width: 100%;
				}
			}
		}

		& .sessions {
			display: inline-block;
			width: 75%;
			vertical-align: middle;
			& ul {
				flex: 0 0 0 100%;
				display: flex;
				align-items: center;
				height: 70px;
				margin: 0%;
				padding: 0%;
				list-style: none;
				justify-content: flex-end;
				transition: all .25s ease;
				will-change: height;
				
				& .itens_header{
					display: inline-block;
					padding: 0 20px;
					& a{
						justify-content: space-between;
						color: white;
						font-weight: 500;
						font-size: 1rem;
						transition: all .2s;
						&:hover{
							color: $gray5;
						}
					}

					& .itemsession{
						position: relative;
						display: block;
						width: 100%;
	
						& .title_is{
							width: 100%;
							display: block;
	
							& .itemsession_title{
								display: block;
								width: 100%;
								font-size: 1rem;
								color:  white;
								text-align: center;
								font-weight: 500;
								padding: 1rem 0;
								@include border-box;
								& i{
									font-size: .65rem;
									color:  white;
									font-weight: 400;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas{
								display: initial;
							}
						}
					}
				}

				& .itens_header2{
					display: inline-block;
					padding: 0 20px;
					& a{
						justify-content: space-between;
						color: white;
						font-weight: 600;
						font-size: 1rem;
						padding: .5rem 1rem;
						background-color: $colorPrimary;
						border: solid 1px white;
						border-radius: 2rem;
						transition: all .2s;
						&:hover{
							background-color: darken($colorPrimary, 5);
						}
					}
				}
			}
		}

		& .menu {
			text-align: right;
			display: inline-block;
			width: 5%;
			vertical-align: middle;
		}

	}
}

.headerindex {
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
}

.header-mob{
	background-color: $colorPrimary;
	padding: .5rem 0 0 0;
	& .box_logo_mobile{
		width: 40%;
		display: inline-block;
		vertical-align: middle;
		& .log{
			display: inline-block;
			vertical-align: middle;
			width: 11rem;
			& img{
				width: 100%;
			}
		}
	}

	& .box_btn_menu_mobile{
		width: 60%;
		display: inline-block;
		vertical-align: middle;
		text-align: right;

		& .btn_busca_home{
			display: inline-block;
			vertical-align: middle;
			width: 70%;
			& p{
				user-select: none;
				width: 100%;
				text-align: center;
				color: white;
				font-weight: 600;
				font-size: 1rem;
				background-color: $colorPrimary;
				border-radius: .1rem;
				padding: .3rem .5rem;
				margin-right: 0 !important;
			}
		}
		& .btn-men{
			display: inline-block;
			vertical-align: middle;
			width: 30%;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.headerpc {
		display: block;
		width: 100%;
		position: relative;

		& .superior {
			display: block;
			width: 100%;
			color: #ffffff;
			z-index: 3;

			/* GRUPO HEADER */
			& .logo {
				display: inline-block;
				margin-left: 0;
				@include border-box;
				vertical-align: middle;
				width: 20%;
				padding: .5rem 0;
				& .img{
					width: 100%;
					display: block;
					
					& img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	
	.headerindex {
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
		z-index: 2;
	}
	
	.headerpc{
		
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
}
