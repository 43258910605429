.footer1{
	border-top: solid 3px $colorPrimary;
	width: 100%;
	display: block;
	background-color: $gray1;
	background-image: url('/jnh/header/texture.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	& .upfooter{
		background-color: $colorPrimary;
		padding: 1rem;
		width: 100%;
		display: block;

		& .contatofooter{
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			& a{
				padding: 0 1rem;
				display: block;
				border-right: solid 2px transparent;
				transition: all .15s;
				border-radius: .2rem;
				& p{
					display: inline-block;
					vertical-align: middle;
					font-weight: 500;
					color: white;
					font-size: .9rem;
				}
				& i{
					vertical-align: middle;
					display: inline-block;
				}
				&:hover{
					border-right: solid 2px white;
					padding-right: 1.5rem;
				}
			}

			& .rs_footer{
				display: block;
				width: 100%;
				& a{
					margin: 0 1rem 0 0;
					display: inline-block;
					vertical-align: middle;
					border-right: none;
					padding: 0;
					
					& p{
						border: solid 1px $gray4;
						border-radius: .2rem;
						transition: all .2s;
						& i{
							color: white;
							padding: .5rem;
							transition: all .2s;
						}
					}
				}
			}
		}
	}

	& .section_atendimento_footer{
		display: block;
		width: 100%;
		& h3{
			font-size: 1.1rem;
			font-weight: 600;
			color: white;
			padding: 1rem 0;
			user-select: none;
		}

		& .horarios{
			display: block;
			width: 100%;
			& p{
				color: white;
				font-size: .9rem;
				font-weight: 600;
				user-select: none;
			}

			& .p2{
				user-select: none;
				text-align: left;
				color: white;
				font-size: .9rem;
				font-weight: 400;
			}
		}
	}

	& .section_imoveis_footer{
		display: block;
		width: 100%;

		& h3{
			text-align: left;
			font-size: 1.1rem;
			font-weight: 600;
			color: white;
			padding: 1rem 0;
			user-select: none;
		}

		& .imoveis_itens{
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding: 0 1rem;
			& a{
				& p{
					color: white;
					font-size: 1rem;
					line-height: 2rem;
					font-weight: 400;
					border-left: solid 2px transparent;
					transition: all .2s;
					&:hover{
						color: $gray4;
						border-left: solid 2px $gray4;
						padding-left: .5rem;
					}
				}
			}

		}

		& .imoveis_itens2{
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding: 0 1rem;

			& a{
				& p{
					color: white;
					font-size: 1rem;
					line-height: 2rem;
					font-weight: 400;
					border-left: solid 2px transparent;
					transition: all .2s;
					&:hover{
						color: $gray4;
						border-left: solid 2px $gray4;
						padding-left: .5rem;
					}
				}
			}
		}
	}

	& .section_endereco_footer{
		display: block;
		width: 100%;
		& h3{
			font-size: 1.1rem;
			font-weight: 600;
			color: white;
			padding: 1rem 0;
			user-select: none;
		}
		& .endereco_itens{
			display: block;
			width: 100%;
			& p{
				color: white;
				font-size: 1rem;
				line-height: 2rem;
				font-weight: 400;
				user-select: none;
			}
		}
	}

	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& p{
			text-align: left;
			color: white !important;
			font-size: .8rem;
			font-weight: 400;
			& strong{
				color: white;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 50%;

			& p{
				text-align: right;
				color: white;
				font-size: .8rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 25%;

			& .logo{
				width: 4rem;
				& img{
					width: 100%;
				}
			}

			& .logo2{
				width: 7rem;
				& img{
					width: 100%;
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.footer1{
		& .devby{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& .developed{
				display: inline-block;
				width: 50%;
				vertical-align: middle;

				& p{
					text-align: right;
					color: $gray3;
					font-size: .9rem;
					font-weight: 400;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 25%;

				& .logo{
					width: 4rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 7rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.footer1{

		& .upfooter{
			& .contatofooter{
				display: block;
				width: 100%;
				& a{
					padding: 0 1rem;
					display: block;
					border-right: none;
					transition: none;
					border-radius: none;
					& p{
						display: inline-block;
						vertical-align: middle;
						font-weight: 400;
						color: white;
						font-size: .9rem;
					}
					& i{
						vertical-align: middle;
						display: inline-block;
					}
					&:hover{
						border-right: none;
						padding-right: none;
					}
				}
	
				& .rs_footer{
					display: block;
					width: 100%;
					& a{
						margin: 0 0 0 1rem;
						display: inline-block;
						vertical-align: middle;
						border-right: 0;
						padding: 0;
						text-align: center;
						
						& p{
							border: solid 1px $gray4;
							border-radius: .2rem;
							transition: all .2s;
							& i{
								color: white;
								padding: .5rem;
								transition: all .2s;
							}
						}
	
						&:hover{
							& p{
								border: none;
								background-color: none;
							}
							border-right: none;
							padding-right: none;
						}
					}
				}
			}
		}

		& .copyright_footer{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& p{
				text-align: left;
				color: $gray3;
				font-size: .9rem;
				font-weight: 400;
				& strong{
					color: $gray3;
				}
			}
		}

		& .devby{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& .developed{
				display: block;
				width: 100%;

				& p{
					text-align: center;
					color: $gray3;
					font-size: .9rem;
					font-weight: 400;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 50%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 9rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}